import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [0];

export const dictionary = {
		"/(site)": [11,[3]],
		"/(site)/blog": [~12,[3]],
		"/(site)/blog/[post]": [13,[3]],
		"/(site)/docs": [~14,[3]],
		"/(site)/docs/[page]": [~15,[3]],
		"/(app)/editor/[file]": [4,[2]],
		"/(app)/files": [5,[2]],
		"/(app)/login": [6,[2]],
		"/(app)/map/[file]": [7,[2]],
		"/(site)/privacy": [16,[3]],
		"/(app)/settings": [8,[2]],
		"/(app)/settings/account-deleted": [9,[2]],
		"/(app)/settings/email-verification": [~10,[2]],
		"/(site)/terms": [17,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';