import { clientInit } from '@jill64/sentry-sveltekit-cloudflare'
import { BrowserTracing, Replay } from '@sentry/svelte'

const onError = clientInit(
  'https://240c6a650c7f5f544af227d5777819c1@o4505750928556032.ingest.sentry.io/4505750932750336',
  {
    sentryOptions: {
      tracesSampleRate: 0.1,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      integrations: [new Replay(), new BrowserTracing()],
    },
  }
)

export const handleError = onError()
